(function() {
    'use strict';

    /**
     * A universal wrapper to apply business rules to single line text inputs.
     *
     * @method alphaTextInput
     *
     * @example
     *      HTML:
     *      <input alpha-text-input
     *             id="some_id"
     *             record-type="some_record_type"
     *             event-context="some_context"
     *             event-record-type="some_record_type"
     *             event-field-type="some_field_type"
     *             read-only-mode="true">
     *
     * @param {Boolean} [read-only-mode] Makes the input permanently read only even if a rule makes it writable.
     */

    angular
        .module('alpha.common.directives.alphaTextInput', [
            'alpha.utils.RequiredFieldUtils',
            'alpha.common.services.ruleExecutionHelper'
        ])
        .directive('alphaTextInput', alphaTextInput);

    alphaTextInput.$inject = ['RequiredFieldService', 'RuleExecutionHelper', 'RecordDataUtil'];

    function alphaTextInput(RequiredFieldService, RuleExecutionHelper, RecordDataUtil) {
        var readOnlyClass = 'readonly';
        var readOnlyRichText = 'alpha-rich-text-read-only';
        return {
            link: link,
            restrict: 'A',
            require: '?ngModel'
        };
        function link(scope, element, attrs, ngModelCtrl) {
            var _readOnlyMode, _readOnlyState;
            RuleExecutionHelper.subscribeToFieldEvents(scope, ['setReadOnly', 'setRequired'], attrs, _eventHandler);
            attrs.$observe('readOnlyMode', function(newVal) {
                if (newVal === 'true') {
                    _readOnlyMode = true;
                    _enableReadOnly();
                } else {
                    _readOnlyMode = false;
                    if (!_readOnlyState) {
                        _disableReadOnly();
                    }
                }
            });

            attrs.$observe('applyRedaction', function(newVal){
                if(newVal === 'true' && ngModelCtrl){
                    var viewValue = ngModelCtrl.$modelValue;
                    var formatters = ngModelCtrl.$formatters;
                    for (var i = formatters.length - 1; i >= 0; --i) {
                        viewValue = formatters[i](viewValue);
                    }
                    ngModelCtrl.$viewValue = viewValue;
                    ngModelCtrl.$render();
                    if(attrs.richText === ''){
                        element.val('******');
                    }
                }
            });

            if(ngModelCtrl){
                ngModelCtrl.$formatters.push(_formatter);
            }

            function _eventHandler(data, event) {
                switch (event.topic) {
                    case 'setReadOnly':
                        _setReadOnly();
                        if(data.value === true && attrs.readOnly ) {
                            element.addClass(readOnlyRichText);
                        }
                        break;
                    case 'setRequired':
                       if(attrs.eventFieldType && !attrs.readOnlyDiv ) {
                            _setRequired();
                        }
                        break;
                }
                function _setReadOnly() {
                    if (data.value === true) {
                        _readOnlyState = true;
                        _enableReadOnly();
                    } else if (data.value === false) {
                        _readOnlyState = false;
                        if (!_readOnlyMode) {
                            _disableReadOnly();
                        }
                    }
                }
                function _setRequired() {
                    if (data.value === true) {
                        _enableRequired();
                    } else if (data.value === false) {
                            _disableRequired();
                 }
                }
            }
            function _enableReadOnly() {
                element.addClass(readOnlyClass).prop('readonly', true).prop('disabled', true);
            }
            function _disableReadOnly() {
                element.removeClass(readOnlyClass).prop('readonly', false).prop('disabled', false);
            }
            function _enableRequired() {
                ngModelCtrl.$validators.required = RequiredFieldService.isRequiredFieldValid;
                ngModelCtrl.$setValidity('required', RequiredFieldService.isRequiredFieldValid(ngModelCtrl.$modelValue));
            }
            function _disableRequired() {
                ngModelCtrl.$setValidity('required', true);
                delete ngModelCtrl.$validators.required;
            }

            function _formatter(modelValue) {
                return RecordDataUtil.getDisplayValueForField(modelValue, { dataType: 'String'}, attrs.applyRedaction);
            }
        }
    }
})();
